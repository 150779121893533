import {ref, reactive, toRefs, computed,watch,defineComponent,onBeforeMount,onMounted,getCurrentInstance,provide,nextTick} from 'vue';
import BgzlContentCardUtil,{IBgzlContentCardDataObj} from './bgzlContentCardUtil';
import WaitBgList from "@/views/project/order/fy/qp/waitBg/WaitBgList.vue";
export default defineComponent({
    name:'BgzlContentCard',
    title:'报关资料内容',
    modelType:'card',
    fullscreen: true,
    setup(props,context){
        let {proxy}=getCurrentInstance() as any;
        const utils=proxy.utils;
        provide('EditTableOwner', proxy);
        let dataObj:IBgzlContentCardDataObj=reactive<IBgzlContentCardDataObj>({
            utilInst:{} as any,
            formRef:null,
            disabled:false,
            refMap:new Map(),
            //卡片传给dialog的初始化参数
            compParams: {
                hasDetails: true,
                detailInfos:{
                    bgzlContentDetail:{},
                },
                details:['bgzlContentDetail'],
                modelPath: utils.OrderProviderApi.buildUrl('/bgzlContent')
            },
            //表单
            form:{},
            //表单验证规则
            rules: {},
            otherParams:{
                type:0,//合同、发票、箱单
            }
        })
        onBeforeMount(()=>{
            dataObj.utilInst=new BgzlContentCardUtil(proxy,dataObj);
        })
        onMounted(()=>{
            nextTick(async()=>{
                await dataObj.utilInst.buildSelectData();
            })
        })
        //---------------------------computed---------------------------
        //明细表格初始化参数
        const cardDetailParam=computed(()=>{
            return (detailType:string) => {
                return {
                    detailParam: {
                        gridTitle:'订单信息',
                        readOnly:dataObj.disabled,
                        canPage: false,
                        canAdd: false,
                        toolBar: [{
                            id: "chooseOrder",
                            title:'选择启票',
                            type: "primary",
                            icon: "iconfont icon-chaxun",
                            clickEvent: 'chooseOrder',
                            param:{}
                        }],
                        queryParam: {
                            detailType: detailType
                        },
                        modelMethod: utils.OrderProviderApi.buildUrl('/bgzlContent/detail?t='+Math.random()*1000)
                    }
                }
            }
        })
        //选择订单按钮事件
        const chooseOrder=async ()=>{
            let type=dataObj.otherParams.type;
            let title='选择启票';
            switch (type) {
                case 2:title='选择合同';break;
                case 1:title='选择发票';break;
            }
            await utils.UtilPub.openDialog({
                proxy:proxy,modelComp:WaitBgList,ownerComp:proxy,type:dataObj.otherParams.type,
                from:'BgzlContentCard',overflow:'hidden',title: title,showFooterBtn:true,fullscreen:true})
        }
        //选择订单弹出框确定事件
        const sureHandler=async (engineRef:any)=>{
            let selectRows=engineRef.pageListRef.getTbInst().getSelection();
            // dataObj.utilInst.doSureChoose(selectRows)
            return true;
        }
        //打开弹出框的回调事件(已请求完/add或/edit，data是返回值)
        const beforeOpen=async(res:any,addOrLoad:string,engineInst:any)=>{
            proxy.dialogInst.options.title=engineInst.engineParams.title;//设置标题
            dataObj.form.type=engineInst.engineParams.type;//[0-合同信息单 1-箱单信息单 2-发票信息单]
            dataObj.form.mainId=engineInst.engineParams.mainId;
            let title='选择启票';
            switch (dataObj.form.type) {
                case 1:title='选择发票';break;
                case 2:title='选择合同';break;
            }
            dataObj.refMap.get('bgzlContentDetail').editParams.toolBar[0].title=title;
        }
        //加载pi参数
        const setParam=(engineInst:any)=>{
            return {cardFrom:engineInst.engineParams.cardFrom,type:engineInst.engineParams.type,mainId:engineInst.engineParams.mainId};
        }

        //行开始编辑事件回调方法，点击某一行编辑或新增一行都会调用该方法。区别：新增行的时候不会传入options,options为undifine，编辑的时候options为{row: row, column: column}
        const beganEdit=async(detailType:string,options:any)=>{
            return true;
        }
        //结束编辑行的时候，为该行指定列设置显示文本，方便不是编辑状态的情况下显示
        const beforeEndEdit=(options:any)=>{
            return true;
        }
        //保存之前，校验整个保存数据是否合法
        const beforeSaveHandler=()=>{
            if(1==1)return true;
            //验证明细表格数据是否合法
            let bgzlContentDetailData=dataObj.refMap.get('bgzlContentDetail').getDetailData();
            if(bgzlContentDetailData.length==0){
                proxy.$message({type:'warning',message:'订单信息不能为空'});
                return false;
            }
            let result=bgzlContentDetailData.findIndex((row:any,index:number)=>{
                let verify=!row.F_HWMS || (!row.F_NUM || row.F_NUM==0) || (!row.F_PRICE || row.F_PRICE==0);
                if(dataObj.form.type==1)verify=verify || ((!row.F_BZ || (!row.F_JS || row.F_JS==0) || (!row.F_MZ || row.F_MZ==0)
                    || (!row.F_JZ || row.F_JZ==0) || (!row.F_TJ || row.F_TJ==0)));
                if(verify){
                    utils.Tools.warning({message:'第'+(index+1)+'行数据有误或不全'});
                }
                return verify;
            })
            return result==-1;
            // return false;
        }
        //保存之后
        const afterSaveHandler=(res:any)=>{
            proxy.engine.engineParams.ownerComp.engine.engineUtil.doAddOrLoad(dataObj.form.mainId,'card');
            return true;
        }
        //明细表格加载完毕事件
        const gridLoaded=(detailType:string,res:any)=>{
            res.rows.forEach((row:any)=> {
                if(!row.F_ID)dataObj.refMap.get(detailType).setInsert(row);
            });
        }
        //计算某行的总金额
        const rowAmount=computed(()=>{
            return (row:any) => {
                if(row.F_NUM && row.F_PRICE){
                    let amount=row.F_NUM*row.F_PRICE;
                    row.F_AMOUNT=amount;
                    return amount;
                }
                row.F_AMOUNT=0;
                return 0;
            };
        })
        //删除之后
        const afterDeleteHandler=(id:string)=>{
            proxy.engine.engineParams.ownerComp.engine.engineUtil.doAddOrLoad(dataObj.form.mainId,'card');
        }
        return{
            ...toRefs(dataObj),proxy,cardDetailParam,chooseOrder,sureHandler,beforeOpen,setParam,beganEdit,beforeEndEdit,beforeSaveHandler,
            afterSaveHandler,gridLoaded,rowAmount,afterDeleteHandler
        }
    }
});