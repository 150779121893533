import BaseBean from "@/utils/BaseBean";

export interface IWaitBgListDataObj {
    utilInst:WaitBgListUtil
    refMap:Map<string,any>
    pageListRef:any
    pageList: any
    otherParams: any
}
export default class WaitBgListUtil extends BaseBean{
    public dataObj:IWaitBgListDataObj;

    constructor(proxy:any,dataObj:IWaitBgListDataObj) {
        super(proxy);
        this.dataObj=dataObj
    }
    //构建下拉数据
    public async buildSelectData():Promise<void>{
        //构建网点下拉数据
        //this.dataObj.networkData=await this.utils.OrderProviderApi.buildNetworkData();
    }
}